.timeline-container {
  width: 100%;
  position: relative;
  padding-top: 30px;
}

.timeline-header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
}
.timeline-content{
  background-color: #FAFAFD;
  min-height: 400px;
}
.background-cercle{
  background-color: #FFFFFF;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-hour {
  flex: 1;
  text-align: center;
  font-size: 16px;
  padding: 5px 0;
 font-weight: 600;
}

.timeline-events {
  position: relative;
  height: 100px;
  margin-top: 40px;
}

.timeline-event {
  position: absolute;
  height: 30px;
  color: #0D2339;
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  display: flex;
  padding-left: 10px;
}
.name-timeline{
  display: flex;
    flex-direction: column;
    line-height: 17px;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
}
.event-marker {
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cercle-i {
  width: 18px;
  height: 18px;
  border-radius:9px;
  background-color:#C75548;
}
.vertical-line {
  position: absolute;
  top: 22px;
  left: 50%;
  width: 1px;
  height: 344px;
  background-color: #C75548;
  transform: translateX(-50%);
}

.bg-white{
  background-color: #FFFFFF;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 31px 12px;
}

.calendar-body {
  display: flex;
  width: 100%;
}

.calendar-week {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 0px;
  border: 1px solid #F2F1F1 !important;
}

.calendar-day-names {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  color: #D3D5D7;
  font-size: 14px;
  font-weight: 400;
}

.calendar-day-name {
  width: 40px;
  text-align: center;
  font-weight: bold;
}

.calendar-days {
  display: flex;
  justify-content: space-between;
}

.calendar-day {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.calendar-cursor {
  cursor: pointer;
}
.calendar-day.inactive {
  color: #ccc;
}

.calendar-day.selected {
  padding-top: 4px;
  border-bottom: 4px solid #C75548;
}
.year-current{
  font-size: 27px;
  color: #61788E;
  font-weight: 700;
}
.three-button{
  background-color: #7DC1BF;
  color: #FFFFFF;
  border-radius: 30px;
  padding: 7px;
  
  position: absolute;
  bottom: 0px;

}
.name-user{
    font-size: 18px;
    font-weight: 600;
    text-align: end;
}
.header-calendar{
  display: flex;
  width: 100%;
  border: 3px solid #F2F1F1;
  background-color: #FFFFFF;
}
.color-red{
  color: #FF5274;
    font-weight: 900;
    font-size: 32px;
}
.rc-color-picker-panel {
  z-index: 10600000 !important; /* Adjust this value if necessary */
}

.first-letter-name{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  font-size: 22px;
  height: 100%;
  font-weight: 700;
  flex-wrap: nowrap;
  border-right: 3px solid #F2F1F1;
}
.add-event{
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: #FF5274;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.div-seven{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  font-size: 22px;
  height: 100%;
  font-weight: 700;
  flex-wrap: nowrap;
  border-right: 3px solid #F2F1F1;
}
.search-and-name{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  width: 100%;
}
.input-search-cal{
  display: flex;
  align-items: center;
  background-color: #F2F1F1;
  border-radius: 23px;
  padding: 6px 14px;

}
.input-search-cal input,.input-search-cal input:hover{
  background-color: #F2F1F1;
  border: none;
  padding: 5px 52px;
}
.three-button span{
  padding: 7px 18px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.three-button .active{
color: #7DC1BF;
background-color: #FFFFFF;
  border-radius: 30px;
}
.titre-month-current{
  font-size: 27px;
  font-weight: 700;
  text-transform: capitalize;
}
.titre-month-last,.titre-month-last{
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
}

