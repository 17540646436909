@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);


@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(/static/media/Roboto-Thin.321de678.ttf) format('ttf');
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  outline: none;
}
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

body {
  background-color: #fff;
  margin: 0;
  font-family: 'Poppins',Roboto, Helvetica, Arial,  sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  color: rgb(85, 85, 85);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0D2339;
}
small {
  font-size: 80%;
}
p{
  margin: 0;
  color: #757575;
}
strong {
  font-weight: 500;
  font-size: 0.875rem;
  color: #2E3842;
}
h1 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}
button{
  color: #fff;
  outline: none
}
button:focus{
  outline: none;
}
h2 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
}
.btn-update-pro{
  border-radius: 21px;
  background: linear-gradient(180deg, #C6E8EE 0%, #5AA9AD 100%);
  color: #FFF;
  text-align: center;
  font-family:"Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.sidebar-profil h3{
  color: #0D2339;
  text-align: center;
  font-family:"Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
}
.box_general h3{
  color: #61788E;
  font-family:"Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

h4 {
  font-size: 18px;
  line-height: 22px;
}

h5 {
  font-size: 16px;
  line-height: 18px;
}

h6 {
  font-size: 1em;
}

a {
  color: #224358;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #0a96de;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}


a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}


ul, ol {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
}
.btn.btn-primary {
    color: #FFF;
    cursor: pointer;
    padding: 12px 30px;
    border-radius: 21px;
    background: linear-gradient(180deg, #C6E8EE 0%, #5AA9AD 100%);
    color: #FFF;
    text-align: center;
    font-family:"Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    border:none;
}
.card-stats{
  background: #FFF;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.25) !important;
  align-items: center;
  justify-content: center;
  max-width: 293.48px;
}
.btn.btn-primary:hover {
  /* box-shadow: 0 14px 26px -12px rgba(5, 150, 222, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(5, 150, 222, 0.2); */
  box-shadow: 0 14px 26px -12px rgb(26 185 185 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(26 185 185 / 20%);
  background-color: #1ab9b9;  
}
.btn.btn-primary:active {
  background-color: #1ab9b9 !important;  
}

.listing-enter {
  opacity: 0;
  max-height: 0px;
  -webkit-transform: translateY(-);
          transform: translateY(-);
  transition: all .3s;  

}
.listing-enter-active {
  max-height: 300px;
  opacity: 1;
}
.listing-exit {
  max-height: 300px;
  opacity: 1;

}
.listing-exit-active {
  max-height: 0px;
  opacity: 0;
  transition: opacity .25s;  
  transition: max-height .3s;  
}
.map-page .content {
  padding-top: 115px;
}
/*----------------------------------------------------*/
/* check box part
/*----------------------------------------------------*/
.checkboxLabel {
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  margin: 12px 30px;
}

.checkboxLabel span {
  position: relative;
  top: 0;
  border: 1px solid #3695eb;
  border-radius: 50%;
  float: left;
  height: 14px;
  width: 14px;
  margin: 0 14px;
  outline-color: #fff;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}

.checkboxLabel input[type="checkbox"] {
  display: none;
}
/* .checkboxLabel input[type=checkbox]:not(.selectt):checked+label .chk-span */
.checkboxLabel input[type="checkbox"]:checked + span {
  border-radius: 0%;
  top: -2px;
  border-left: 2px solid #8dc63f;
  border-bottom: 4px solid #8dc63f;
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0);
  -webkit-transform: rotate(-45deg) scaleY(0.5);
  transform: rotate(-45deg) scaleY(0.5);
}
.working-time{
  padding: 60px 20px;
  border: 2px solid #eee;
  position: relative;
  border-radius: 10px;
  transition: border 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.working-time-label{
  padding: 5px 24px;
  font-size: 24px;
  font-weight: 500;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  /* transition: all 0.3 ease-in-out; */
  -webkit-transform: translate(0px, 5px) scale(1);
          transform: translate(0px, 5px) scale(1);
}
.hour-actions{
  padding: 5px 24px;
  font-size: 24px;
  font-weight: 500;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(-14px, -24px);
          transform: translate(-14px, -24px);

}
.working-time:hover {
  border: 2px solid #008ea2;

}
.working-time:hover .working-time-label{
  -webkit-transform: translate(14px, -24px) scale(0.75);
          transform: translate(14px, -24px) scale(0.75);
  color: #008ea2;
}
  footer {
    padding: 15px 0;
  }
  
  footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
  }
  
  footer ul li {
    display: inline-block;
  }
  
  footer ul li a {
    color: inherit;
    padding: 15px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    text-decoration: none;
    position: relative;
    display: block;
  }
  
  footer ul li a:hover {
    text-decoration: none;
  }


.add-event-modal .modal-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


 .modal .modal-dialog .modal-header .close, .modal .modal-dialog .modal-header .modal-title{
 margin: 0;
 padding: 5px
}
.categorie-color{
  padding: 5px 12px;

}

.actions-button{
  display: flex;
  border-radius: 12px;
  padding: 0 12px;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #5BC236;
  background-size: 300% 100%;
  background-image: linear-gradient(to right, #fff, #fff, #fff, #5BC236);
  cursor: pointer;
  transition: all 0.4s
}
.actions-button svg{
  color: #5BC236;
  /* color: #32CD32; */
}
.actions-button:hover{
  color: #008ea2;
  background-position: 100% 0;
}
.actions-button:hover svg{
  color: #008ea2;
}
.actions-button p{
  margin: 0
}

.delete-button {
  border: 1px solid #d92550;
  padding:4px 8px;
  border-radius:8px;
  cursor: pointer;
  transition: all 0.3s;
}
.delete-button:hover {
  background-color: #d92550;
}

.delete-button:hover svg {
  color: #fff !important;

}

.rbc-toolbar{
  min-height: 75px;
  margin-bottom: 2rem;
}
.rbc-toolbar .rbc-toolbar-label{
  display:flex;
  justify-content:center;
  align-items:center;
}
.rbc-toolbar .rbc-toolbar-label p{
  border: 1px solid #008ea2;
  padding: 5px 12px;
  border-radius: 12px;
}


.navbar-brand img{
  width: 100px;
}
.rbc-rtl .rbc-btn-group > button{
  border: 0 transparent;
  min-width: 100px;
  font-size: 15px;
  display: inline-block;
  padding: 10px 16px;
  margin: 0 3px 0 0;
  cursor: pointer;
  moz-transition: background-image .4s ease-in-out;
  transition: background-image .4s ease-in-out;
  color: #000;
  background-size: 300% 100%;
  background-image: linear-gradient(to right, #fff, #fff, #fff, #008ea2);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.rbc-rtl .rbc-btn-group > button:hover, .rbc-rtl .rbc-btn-group > button.rbc-active {
  /* border-color: #4B9FE1; */
  color: #008ea2;
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}


.rbc-toolbar button:focus {
  outline: none;
  box-shadow: 0 1px 4px 0 rgba(45, 54, 65, 0.75);
}

.rbc-toolbar button:disabled, .rbc-toolbar  button[disabled] {
 cursor: not-allowed;
}

.rbc-btn-group > button{
  border-radius: 20px !important;
  margin-left: 5px !important;
  border: 0 !important;
  padding: 10px 25px 10px 25px !important;
  font-size: 14px;
  line-height: 1.57;
  transition: border .2s linear,color .2s linear,width .2s linear,background-color .2s linear;
  -webkit-font-smoothing: subpixel-antialiased;
  background: #00a2b7 !important;
  color: #fff !important;
  box-shadow: 0 10px 30px 0 rgb(0 172 193 / 30%);
}
.rbc-btn-group > button.rbc-active{
  background: #1c6f7c !important;
}

.rbc-rtl .rbc-btn-group > button:last-child {
  /* border-radius: 30px 12px 12px 30px !important; */
}

.rbc-btn-group > button:first-child {
  /* border-radius: 12px 30px 30px 12px !important; */
}


.menu-patient-icon{
  border-bottom-left-radius: 44px;
  border-bottom-right-radius: 37px;
  background-color: #FFF;
  WIDTH: 100%;
  height: 81px;
  top: 32px;
}
.menu-patient-icon .active{
  width: 50px;
  height: 50px;
  padding: 0;
  display: flex;
  align-items: center;
  border-radius: 25px;
  justify-content: center;
  background: linear-gradient(90deg, #CAEBF6, #7DC1BF);
}
.menu-patient-icon .active svg{
  color:#FFFFFF !important;
}
.item-cal{
  padding-top: 12px;
}
.calendrier-list-court{
  padding: 20px;
  background-color: #FFFFFF;
}
.title-calendrier{
    min-width: 180px;
    font-size: 14px;
    font-weight: 500;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #FF5252 !important;
  background-color: #FF5252 !important;
}
.flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}
.search-container {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 0 5px;
  height: 35px;
  min-width: 209px;
  box-sizing: border-box;
}

.search-input {
  flex: 1 1;
  border: none;
  outline: none;
  font-size: 14px;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}

.search-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #A6A6A6;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #FFF;
  border-radius: 15px;
}

.search-button:hover {
  color: #A6A6A6;
}


@media only screen and (max-width: 767px) {
  .rbc-btn-group button {
    min-width: 80px;
  }
  .logoutbtn{
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: end;
  }
}
.input-search div{
  width: 100%;
}

.footer-phrase{
    margin-top: 13px;
    padding-left: 11px;
    color: #61788E;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.title_page_pole{
    color: #0D2339;
    font-family:"Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 123.187%; /* 29.565px */
    letter-spacing: 0.72px;
    text-transform: uppercase;
    padding-bottom:40px;
}
.title_pole{
  color: #0D2339;
  font-family:"Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-bottom:34px;
  padding-top: 22px;
}
.select_pole{
  border-radius: 18.938px;
  border: 1.5px solid #0D2339;
  background: #FFF;
  color: #61788E;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  height: 54px;
  font-weight: 400;
}
.custom-radio-button {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #61788E; /* Define your desired border color */
  border-radius: 50%; /* Create a circular shape */
  margin-right: 20px;
}

/* Style the radio button when it's checked */
input[type="radio"]:checked + .custom-radio-button {
  background-color: #61788E; /* Set a background color when checked */
}
.custom-button{
  color: #FFF;
  font-family:"Montserrat", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  border-radius: 20px;
  background: linear-gradient(180deg, #CAEBF6 0%, #5AA9AD 100%);

  padding: 14px 125px 14px 125px;
    border: none;
    margin-top: 45px;
    margin-bottom: 20px;
}
.custom-r{
  display: inline-flex;
  border-radius: 18.938px;
  border: 1.5px solid #0D2339;
  background: #FFF;
  width: 100%;
  padding: 20px 20px 20px 20px;
  color: #61788E;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.custom-r input[type="radio"]{
  opacity: 0;
  display: none;
}
.input_pole{
  border-radius: 18.938px;
  border: 1.5px solid #0D2339;
  background: #FFF;
  color: #61788E;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  height: 54px;

}
.box_general_pole {
  width: 100%;
  padding: 30px;
  margin-bottom: 15px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.25);
}
.box_general {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 15px;
  border-radius: 20px;
  box-shadow: 4px 4px 50px 0px rgba(166, 166, 166, 0.56);
}


.img-fluid, .react-fancybox .thumbnail img {
  max-width: 100%;
  height: auto;
}
.react-fancybox .box{
  z-index: 9999;
}
.react-fancybox .box .image-box{
  max-height: calc(100vh - 80px);
    width: auto;
}
.react-fancybox .box .image-box img:not(.close-btn) {
  height: 100%;
  width: auto;
}
.aside {
  transition: all .3s ease-in-out;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
} 
.aside .sidebar-profil{
  padding-top: 0px;
  padding-bottom: 1px;
  /* position: relative; */
  top: 0px;
  border: 1px solid #e1e8ed;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 4px 4px 43px 0px #A6A6A6;
}
.aside .sidebar-profil .profil-content{
  padding:20px;
}
.aside .sidebar-profil .profil-content figure {
  overflow: hidden;
}
.aside .sidebar-profil .profil-content figure img{
  width: 100%;
  border-radius:50%
}
.aside .sidebar-profil .profil-content .medecin-details{
  padding: 0 2rem 2rem;
  text-align: center;
}
.profil-content small {
  color: #999;
  font-weight: 600;
}
.profil-content h1 {
  font-size: 21px;
  font-size: 1.3125rem;
}
.table-striped tbody tr td:last-child{
  width: 100px;
}
.MuiTimelineItem-missingOppositeContent:before{
  flex: 0 1 !important;
  padding: 0 !important;
}
.MuiTimelineDot-root{
  margin-top: 13px !important;
}
.badge_list_1 {
  position: relative;
  top: -2px;
  margin-left: 10px;
}
.profil-content ul.statistic {
  padding-bottom: 5px;
  display: flex;
  justify-content: space-around;
}

.profil-content ul.contacts {
  text-align: left;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  padding: 25px;
  /* margin: 0 -25px 25px; */
}

.profil-content ul.statistic li {
  border-radius: 3px;
  line-height: normal;
  color: #fff;
  padding: 3px 10px;
  font-size: 12px;
  font-size: .75rem;
}

.profil-content ul.statistic li, .profile ul.statistic li {
  background-color: #74d1c6;
  font-weight: 600;
  min-width: 95px;
  display: inline-block;
}
.profil-content ul.contacts li {
  margin-bottom: 15px;
  font-size: 13px;
}

.profil-content ul.contacts li h6 {

  margin-bottom: 3px;
  color: #61788E;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
}
.profil-content figure {
  /* margin: -1px -26px 25px -26px; */
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}
@media (max-width: 991px) {
  .profil-content figure {
    /* For less than 991px */
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 25px auto;
  }
  .aside{
    position: relative;
    top: 0
  }
}

@media (min-width: 1200px) {


  .aside .sidebar-profil .profil-content .medecin-details{
    padding: 0 ;
  }
  .profil-content ul.contacts{
    padding: 8px
  }
}
@media (max-width: 1200px) {
  .aside{
    margin-bottom: 2rem;

  }

}


tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.datepicker thead tr th.prev,
 .datepicker thead tr th.next {
  cursor: pointer;
}

.datepicker thead tr:first-child th,
 .datepicker tfoot tr th {
  cursor: pointer;
}
.datepicker table tr td,
 .datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border: none;
}
.datepicker.dropdown-menu th,
 .datepicker.datepicker-inline th,
  .datepicker.dropdown-menu td,
   .datepicker.datepicker-inline td {
  padding: 0px 5px;
}
.datepicker th.datepicker-switch {
  width: 145px;
}
.datepicker table tr td,
 .datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border: none;
}
.datepicker table tr td.old,
 .datepicker table tr td.new {
  color: #999;
}

.datepicker table tr td.day {
  background-color: #f8f8f8;
  border: 2px solid #fff;
  cursor: pointer;
}
.datepicker table tr td,
 .datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border: none;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker table tr td.active{
  color: #fff;
  background-color: #333;
  border-color: #fff;
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker .disabled.disabled-date.day,
.datepicker .disabled.disabled-date.day:hover {
  background-color: #eb525b !important;
  color: #fff !important;
}
.datepicker table tr td.disabled, 
.datepicker table tr td.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}

.card-profile {
  display: inline-block;
  position: relative;
  text-align: center;
  width: 25%;
  margin: 4rem 4rem 4rem 4rem;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 300ms;
}

.card-profile:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  box-shadow: 0 14px 14px 0 rgba(0, 0, 0, 0.14), 0 13px 11px -12px rgba(0, 0, 0, 0.2), 0 11px 15px 0 rgba(0, 0, 0, 0.12);
}

.card-profile .card-avatar,
.card-testimonial .card-avatar {
  max-width: 130px;
  max-height: 130px;
  margin: -50px auto 0;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card-profil img {
  width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
  border-style: none;
}

.card-profil .table {
  padding: 15px 30px;
}

.card-caption {
  font-weight: 700;

}

.card-caption,
.card-caption a {
  color: #333;
  text-decoration: none;
}

.category {
  position: relative;
  line-height: 0;
  margin: 15px 0;
}

.card-profil .ftr {
  margin-top: 15px;
}

@media (max-width: 767px) {
 
.card-profile {

  width: 100%;
margin: 0;
}
}


/* 
==========================================================================
   #BUTTON
   ========================================================================== */
.btn-submit {
  display: inline-block;
  line-height: 80px;
  background: #3695EB;
  border-radius: 10px;
  text-transform: capitalize;
  color: #333;
  font-size: 18px;
  transition: all 0.4s ease;
  padding: 0 30px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.btn-submit:hover {
  background: #3695EB;
}

@media (max-width: 767px) {
  .btn-submit {
    width: 100%;
  }
}

.m-t-0 {
  margin-top: 0;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-35 {
  margin-top: 35px;
}


/* ==========================================================================
   #FORM
   ========================================================================== */
input,
textarea {
  outline: none;
  margin: 0;
  border: none;
  box-shadow: none;
  width: 100%;
  font-size: 18px;
}

input:disabled {
  cursor: pointer;
  background: transparent;
}

textarea {
  resize: none;
}

button {
  cursor: pointer;
}

/* CHECKBOX */
/* Radio button */
.radio-row {
  padding-top: 10px;
}

.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.radio-container:hover input~.radio-checkmark {
  background-color: #fff;
}

.radio-container input:checked~.radio-checkmark {
  background-color: #fff;
}

.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-container input:checked~.radio-checkmark:after {
  display: block;
}

.radio-container .radio-checkmark:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #00ad5f;
}

.input-group {
  position: relative;
  margin-bottom: 23px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 16px 20px;
  padding-bottom: 10px;
  background: #fff;
  border-radius: 10px;
  float: left;
  margin-right: 10px;
}

.form::after {
  content: "";
  clear: both;
  display: table;
}

.input-icon {
  font-size: 24px;
  color: #808080;
  position: absolute;
  line-height: 60px;
  right: 20px;
  top: 0;
  width: 20px;
  background: #fff;
  text-align: center;
  cursor: pointer;
}

.input-icon::before {
  display: block;
  transition: all 0.4s ease;
}

.label {
  font-size: 12px;
  color: #999;
  text-transform: uppercase;
  display: block;
  font-weight: 700;
}

.input--large {
  width: 300px;
}

.input--medium {
  width: 190px;
}

@media (max-width: 767px) {

  .input--large,
  .input--medium {
    width: 100%;
  }
}

.input--style-1 {
  font-size: 18px;
  padding: 9px 0;
  color: #666;
}

.input--style-1::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #333;
}

.input--style-1:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #333;
  opacity: 1;
}

.input--style-1::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #333;
  opacity: 1;
}

.input--style-1:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #333;
}

.input--style-1:-ms-input-placeholder {
  /* Microsoft Edge */
  color: #333;
}

.m-b-0 {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .m-b-0 {
    margin-bottom: 26px;
  }
}


.navig{
  cursor: pointer;
}


.indent_title_in {
  position: relative;
  padding-left: 60px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .indent_title_in {
    /* For less than 767px */
    padding-left: 0;
  }
}
.indent_title_in svg {
  font-size: 40px;
  position: absolute;
  left: 0;
  color: #1ab9b9;
  top: 0;
}
@media (max-width: 767px) {
  .indent_title_in svg {
    /* For less than 767px */
    position: static;
  }
}
.indent_title_in h3 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 21px;
}
.indent_title_in p {
  color: #999;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-size: 0.875rem;
}

.wrapper_indent {
  padding-left: 60px;
}
.wrapper_indent p{
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .wrapper_indent {
    /* For less than 767px */
    padding-left: 0;
  }
}


ul.list_edu li strong {
  font-weight: 500;
}
ul.list-items li{
  display: flex;

}
ul.list-items li strong{
  display: block;
  
}
ul.list-items li strong{
  display: block;
  width: 30%;
  
}
ul.list-items li p{
  display: block;
  width: 70%;
  
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot{
  padding-left: 65px;
  height: 60px;
  border: 0px solid
}
.search .MuiInput-underline:before,.search  .MuiInput-underline:after,.search  .MuiInput-underline:hover:after,.search  .MuiInput-underline:focus:after{
  border-bottom: 0px !important;
  content: "" !important
}


.card-table .card-body {
  padding: 0;
}
.card-table .card-body .table > thead > tr > th {
  border-top: 0;
}
.card-table .table tr td:first-child,
.card-table .table tr th:first-child {
  padding-left: 1.5rem;
}
.card-table .table tr td:last-child,
.card-table .table tr th:last-child {
  padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
  border-top: 1px solid #e2e5e8;
  padding: 1rem 0.75rem;
  white-space: nowrap;
}

.card {
  margin-bottom: 1.875rem;
  border-radius: 10px;
  background: #FFF;
}
.card-body {
padding: 1.5rem;
}
.card-title {
  margin-bottom: 15px;
}
.card-header {
border-bottom: 1px solid #f0f0f0;
padding: 1rem 1.5rem;
}
.card-footer {
background-color: #fff;
border-top: 1px solid #f0f0f0;
padding: 1rem 1.5rem;

}
.card .card-header {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}
.card .card-header .card-title {
  margin-bottom: 0;
}

.type-file-span{
  font-weight: 700;
}

.searchbar-result-profile-avatar {
  flex-basis: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 4%

}
.searchbar-result-profile-avatar img{
  border-radius: 50%;

}


tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.datepicker thead tr th.prev,
 .datepicker thead tr th.next {
  cursor: pointer;
}

.datepicker thead tr:first-child th,
 .datepicker tfoot tr th {
  cursor: pointer;
}
.datepicker table tr td,
 .datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border: none;
}
.datepicker.dropdown-menu th,
 .datepicker.datepicker-inline th,
  .datepicker.dropdown-menu td,
   .datepicker.datepicker-inline td {
  padding: 0px 5px;
}
.datepicker th.datepicker-switch {
  width: 145px;
}
.datepicker table tr td,
 .datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border: none;
}
.datepicker table tr td.old,
 .datepicker table tr td.new {
  color: #999;
}

.datepicker table tr td.day {
  background-color: #f8f8f8;
  border: 2px solid #fff;
  cursor: pointer;
}
.datepicker table tr td,
 .datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border: none;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker table tr td.active{
  color: #fff;
  background-color: #333;
  border-color: #fff;
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker .disabled.disabled-date.day,
.datepicker .disabled.disabled-date.day:hover {
  background-color: #eb525b !important;
  color: #fff !important;
}
.datepicker table tr td.disabled, 
.datepicker table tr td.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}
 /* CUSTOM CSS */

.date-range-calendar {
  margin: auto auto 0;
  /* border: 1px solid #eee; */
  /* box-shadow: 0 0 18px rgba(0, 0, 0, 0.1); */
  background: #fff;
  font-family: Arial;
  position: relative;
}
.link-acc{
  width: 33%;
}
.header-c{
  display: flex;
  align-items: center;
  justify-content: center;
    width: 100%;
}
.sidebar-color{
  border-radius: 23.862px;
  background: linear-gradient(180deg, #C6E8EE 0%, #1AB9B9 100%);
  padding: 13px 37px !important;

}
.active .sidebar-color{
  border-radius: 23.862px;
  background: linear-gradient(180deg, #C6E8EE 0%, #1AB9B9 100%);
  padding: 17px 60px !important;
  font-size:18px !important;

}
.sidebar-color .item-txt{
  font-size:12px !important;
}
.active .sidebar-color .item-txt{
  font-size:18px !important;
}
.input-search{
  border-radius: 21.081px;
  background: #F0F7F7;
  max-width: 400px;
  min-width: 180px;
  padding: 2px 20px 2px 20px;
  display: flex;
}
.logoutbtn{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.date-range-calendar .range-calendar #date-range-preview,
.date-range-calendar .range-calendar #date-range-next {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  border: 0;
  background: transparent;
  cursor: pointer;
  /* border-top: 1px solid #eee; */
  z-index: 1;
  color: #000;
}

.date-range-calendar .range-calendar #date-range-preview:focus,
.date-range-calendar .range-calendar #date-range-next:focus {
  outline: none;
  border: none;

}
.rejoignez,.rejoignez:hover,
.rejoignez:focus,.makeStyles-submit-5{
  border-radius: 13px;
  border: 2px solid #C6E8EE;
  background: linear-gradient(180deg, #C6E8EE 0%, #1AB9B9 100%);
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 15px;
  width: 100%;
  margin-top: 10px;
}
.desc-lgn-pro{
  color: #0D2339;
  text-align: center;
  font-family:"Montserrat", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.date-range-calendar #date-range-next {
  border-left: 1px solid #eee;
}

.range-calendar {
  clear: both;
  overflow: hidden;
  display: flex;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.range-calendar .calendar-pagination {
  width: 24px;
}
.range-calendar .calendar-pagination button {
  padding: 0
}


.range-calendar .calendar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.range-calendar .calendar .calendar-day {
  flex: 1 0;
  margin: 0 12px;
}

.range-calendar .calendar-day .calendar-day-title {
  text-align: center;
  color: #000;
  background: transparent;
}
.range-calendar .calendar-day .cell {
  padding: 6px 30px;
  margin: 10px 0;
  background-color: #46b1fc;
  border-radius: 2rem;
  text-align: center;
  color: #000;
  cursor: pointer;
  transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 100ms;
}

.range-calendar .calendar-day .cell:hover{
  background: #008ea2;
}

.range-calendar .calendar-day .calendar-day-name {
  font-weight: bold;
  font-size: 16px;
  margin: 0 4px
}

.range-calendar .calendar-day .calendar-day-date {
  font-weight: 400;
  font-size: 12px;
  word-break: keep-all;
  display: block;
}

.selected-date-area {
  margin: 50px auto;
  padding: 50px 30px;
  border: 1px solid #eee;
  background: white;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
}

.selected-date-area > span {
  font-size: 1.3rem;
  font-family: Arial;
  color: #666;
}

.date-range-calendar h4 {
  font-size: 22px;
}

.hours{
  /* border: 1px solid #eee; */
  margin: 20px auto;
  /* box-shadow: 0 0 18px rgba(0, 0, 0, 0.4); */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}
.hours .hours-element{
  /* width: 65px; */
  /* height: 40px; */
  font-size: 22px;
  padding: 9px 30px;
  border-radius: 20px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
}
.hours .hours-element{
  background-color: #8dc63f;
}
.hours.medecin .hours-element{
  background-color: rgba(0, 0, 0, 0.09);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
}
.hours .hours-element.disabled{
  background-color: #ff0505;
  cursor: not-allowed;
} 
.hours.medecin .hours-element.disabled{
  background-color: #8dc63f;
  cursor: not-allowed;
  color: #fff;
}
.range-calendar .calendar-day .hours-element-disabled {
  width: 100%;
  margin: 10px 0;
  height: 34px;
  line-height: 34px;
  display: flex;
  align-items: center;
}
.range-calendar .calendar-day .hours-element-disabled .hour-empty {
  margin: 0 auto;
    width: 12px;
    height: 2px;
    background-color: rgba(67,95,113,0.2);
    border-radius: 1px;
}

.pe-layout {
  flex-basis: 50%;
  max-width: 50%;
}
.renez-vous-label {
  padding: 6px 24px;
  margin: 16px 0;
  color: #1ab9b9;
  font-size: 18px;
  font-weight: 500;
}
.renez-vous-paragraphe {
  padding: 6px 24px;
  margin: 16px 0;
  color: #000;
  font-size: 16px;
  font-weight: 300;
}
.type-consultation {
  padding: 6px 24px;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  background-color: #ccecfa;
}
.timeline-container {
  width: 100%;
  position: relative;
  padding-top: 30px;
}

.timeline-header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
}
.timeline-content{
  background-color: #FAFAFD;
  min-height: 400px;
}
.background-cercle{
  background-color: #FFFFFF;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-hour {
  flex: 1 1;
  text-align: center;
  font-size: 16px;
  padding: 5px 0;
 font-weight: 600;
}

.timeline-events {
  position: relative;
  height: 100px;
  margin-top: 40px;
}

.timeline-event {
  position: absolute;
  height: 30px;
  color: #0D2339;
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  display: flex;
  padding-left: 10px;
}
.name-timeline{
  display: flex;
    flex-direction: column;
    line-height: 17px;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
}
.event-marker {
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cercle-i {
  width: 18px;
  height: 18px;
  border-radius:9px;
  background-color:#C75548;
}
.vertical-line {
  position: absolute;
  top: 22px;
  left: 50%;
  width: 1px;
  height: 344px;
  background-color: #C75548;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.bg-white{
  background-color: #FFFFFF;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 31px 12px;
}

.calendar-body {
  display: flex;
  width: 100%;
}

.calendar-week {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 0px;
  border: 1px solid #F2F1F1 !important;
}

.calendar-day-names {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  color: #D3D5D7;
  font-size: 14px;
  font-weight: 400;
}

.calendar-day-name {
  width: 40px;
  text-align: center;
  font-weight: bold;
}

.calendar-days {
  display: flex;
  justify-content: space-between;
}

.calendar-day {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.calendar-cursor {
  cursor: pointer;
}
.calendar-day.inactive {
  color: #ccc;
}

.calendar-day.selected {
  padding-top: 4px;
  border-bottom: 4px solid #C75548;
}
.year-current{
  font-size: 27px;
  color: #61788E;
  font-weight: 700;
}
.three-button{
  background-color: #7DC1BF;
  color: #FFFFFF;
  border-radius: 30px;
  padding: 7px;
  
  position: absolute;
  bottom: 0px;

}
.name-user{
    font-size: 18px;
    font-weight: 600;
    text-align: end;
}
.header-calendar{
  display: flex;
  width: 100%;
  border: 3px solid #F2F1F1;
  background-color: #FFFFFF;
}
.color-red{
  color: #FF5274;
    font-weight: 900;
    font-size: 32px;
}
.rc-color-picker-panel {
  z-index: 10600000 !important; /* Adjust this value if necessary */
}

.first-letter-name{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  font-size: 22px;
  height: 100%;
  font-weight: 700;
  flex-wrap: nowrap;
  border-right: 3px solid #F2F1F1;
}
.add-event{
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: #FF5274;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.div-seven{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  font-size: 22px;
  height: 100%;
  font-weight: 700;
  flex-wrap: nowrap;
  border-right: 3px solid #F2F1F1;
}
.search-and-name{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  width: 100%;
}
.input-search-cal{
  display: flex;
  align-items: center;
  background-color: #F2F1F1;
  border-radius: 23px;
  padding: 6px 14px;

}
.input-search-cal input,.input-search-cal input:hover{
  background-color: #F2F1F1;
  border: none;
  padding: 5px 52px;
}
.three-button span{
  padding: 7px 18px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.three-button .active{
color: #7DC1BF;
background-color: #FFFFFF;
  border-radius: 30px;
}
.titre-month-current{
  font-size: 27px;
  font-weight: 700;
  text-transform: capitalize;
}
.titre-month-last,.titre-month-last{
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
}


main.medecin-site{
    background: #f5f8fa;
    position: relative;
    z-index: 1;
}

.sidebar .profil {
    margin-top: 25px;  
    padding: 20px;
    display: flex;
    position: relative;
   
  }

  .sidebar .profil .circle_online {
    left: 8%
  }
  .sidebar .profil .profile-img {
    max-width: 100%;
   width: 80%;
   border-radius: 50%;
   
  }
  
  
  .sidebar .nav .nav-item {
   width: 100%;
   margin-top: .8rem;
  
  }

  .circle_online{
    color: rgb(92, 184, 92);
    width: 1em;
    height: 1em;
    position: absolute;
    top: 12px;
    left: 4%;
  }
 
  .icon {
    color: #fff !important
  }
  .selecting-img-container, .rejected-img-container{
    /* width: auto; */
    /* height: 2rem; */
    display: flex;
    /* height: auto; */
    justify-content: center;
    position: relative;
  }
  .selecting-img-container img{
    border-radius: 15px;
  }
  .rejected-img-container img{
    /* width: 80px; */
    width: 40%;
    margin: 0 auto;
    border-radius: 15px;
  }
  .dropzone:focus {
    border-color: #2196f3 !important
  }
  .images-saved {
    padding: 12px;
    border : 1px solid #eee
  }
  .image-saved {
   position :relative;
   
  }
  .image-saved  .delete-button, .selecting-img-container .delete-button{
   position :absolute;
   right: -25px;
   top: -25px;
   color: #d92550;
   font-size: 24px
  }
  .image-saved  .delete-button:hover{
    color: #000
 
   }
  .image-saved img{
    margin: 0 auto;
    border-radius: 15px;
  }


  .discussion-container{
    border-radius: 0 0 15px 15px;
    box-shadow: 0 2px 8px rgba(54, 149, 235, 0.3);
  }
  .chat {
      border-radius: 8px;
      padding-bottom: 3rem;
  }
    .discussion{
      height: 70vh;
      border: 1.2px solid #008ea2;
      border-radius:  0 0 15px 15px;
      z-index: 9999;
    }
    .discussion .chat-messages {
      /* position: absolute; */
      height: 100%;
      width: 100%;
      /* top: 78px; */
      z-index: 9;
      /* border: 1.2px solid #008ea2; */
      border-radius: 0 0 15px 15px;
    }
    
    
  .discussion .chat-messages .content {
    height: calc(100% - 60px);
    overflow: hidden;
    position: relative;
    padding-bottom: 15px;
  }
  
  .messages {
    height: auto;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
  .messages::-webkit-scrollbar {
    width: 3px;
    background: rgba(0, 0, 0, 0);
  }
  .messages::-webkit-scrollbar-thumb {
    background-color: #008ea2;
  }
  .messages .messageContainer {
    display: inline-block;
    margin: 15px 15px 5px 15px;
    width: calc(100% - 25px);
    font-size: 0.9em;
  }
  .messages .messageContainer .message{
    display:flex;
    flex-direction: column;
  }
  .messages .messageContainer.sent .message{
    align-items: flex-end;
  }
  .messages .messageContainer.replies .message{
    align-items: flex-start;
  }
  .messages .messageContainer.replies .message .message-container{
    flex-direction: row-reverse;
  }
  .document-form .messageText p{
    padding: 10px;
  }
  .document-form .messageText p{
    padding: 10px;
  }

  .MuiPopover-paper {
    padding: 12px;
    border-radius: 12px !important;
    margin-top: 8px;
  }

  .messages .messageContainer:nth-last-child(1) {
    margin-bottom: 20px;
  }
  .messages .messageContainer.sent img {
  }
  
  .messages .messageContainer.sent .messageText, .document-form .messageText {
    background: #008ea2;
    color: #fff;
    overflow-wrap: break-word;
    border-radius: 10px;
  }
  .messages .messageContainer.sent .messageText p{
    color: #fff;

  }
  .messages .messageContainer .document-icon-container, .document-form .document-icon-container {
    padding: 15px;  
  }
  .messages .messageContainer .document-icon-container .document-icon, .document-form .document-icon-container .document-icon {
    padding: 15px;  
    border-radius: 50%;
    background-color: #002b4e;
  }
  .messages .messageContainer .document-icon svg{
    fill: #fff
    
  }
  .messages .messageContainer.replies .messageText {
    background: #f5f5f5;
    text-align:left;
    overflow-wrap: break-word;
    border-radius: 10px;
  }
  .messages .messageContainer p {
    display: inline-block;
    padding: 10px 15px;
    font-weight: 400;
    border-radius: 20px;
    max-width: 205px;
    line-height: 130%;
  }
  @media screen and (min-width: 735px) {
     .messages .messageContainer p {
        max-width: 300px;
    }
  
  }
  .discussion .messageForm{
    background-color:#f5f5f5;
    padding: 12px 0px;
    position: absolute;
    width: calc(100% - 1px);
    bottom: 1.8px;
    right: 0.8px;
    border-radius: 0 0 15px 14px;
  }

  
  .discussion .chatContainer .inputChat {
    font-family: "Poppins";
    border: 0.5px solid rgba(0,0,0,0.11);
    width: 100%;
    padding: 11px 32px 10px 20px;
    font-size: 1em;
    color: #32465a;
    background-color: rgba(0,0,0,0.1);
    border-radius: 30px;
  }
  
  .discussion .chatContainer .inputChat:focus {
    outline: none;
  }
  
  .discussion .chatContainer .SendMsgBtn, .document-form .SendMsgBtn{
    border: none;
    padding: 10px 12px;
    cursor: pointer;
    color: #f5f5f5;
    background: #008ea2;
    border-radius: 100%;
    transition: all 0.3s;
  }
  .document-form .SendMsgBtn{
    padding: 10px 20px;
  }
  
  .discussion .chatContainer .SendMsgBtn:hover, .document-form .SendMsgBtn:hover {
    background: #435f7a;
  }
  
  .discussion .chatContainer .SendMsgBtn:focus, .document-form .SendMsgBtn:focus {
    outline: none;
  }
  .discussion .chatContainer .SendMsgBtn svg, .document-form .SendMsgBtn svg {
    margin-right: 2px;
  }
  
  @media screen and (max-width: 735px) {
    .discussion .chatContainer .inputChat {
      padding: 15px 32px 16px 8px;
  }
 
  }

  .infoBar {
    height: 78px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #008ea2;
    border-radius: 4px 4px 0 0;
    width: 100%;
    padding-right: 15px;

  }
  .action{
    border-radius: 50%;
    /* margin-left: .4rem;
    margin-right: .5rem; */
    padding: 18px;
    opacity: 1;
}
.video-call .action{
  background-color: transparent;

}
.phone-call .action{
  background-color: transparent;
}
.leftInnerContainer {
  display: flex;
  align-items: center;
  margin-left: 5%;
  color: white;
}

.rightInnerContainer {
  display: flex;
  flex: 0.5 1;
  justify-content: flex-end;
  margin-right: 5%;
}

.centerInnerContainer {
  display: flex;
  flex: 0.5 1;
  justify-content: flex-end;
}

.onlineIcon {
  margin-right: 5%;
}
.profile {
  width: 100%;
  padding: 4px;
  background: #008ea2;
  color: #fff;
  display: flex;
  align-items: center;
}
.profile img {
  width: 70px;
  border-radius: 50%;
  padding: 3px;
  background-color: #fff;
  border: 2px solid #008ea2;
  height: auto;
  transition: 0.3s border ease;
  margin-right: 1rem;
}

.profile img.online {
  border: 2px solid #2ecc71;
}

.profile img.away {
  border: 2px solid #f1c40f;
}

.profile img.busy {
  border: 2px solid #e74c3c;
}

.profile img.offline {
  border: 2px solid #95a5a6;
}
.profile p {
color: #f5f5f5;
font-size: 18px;
}


.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: #111;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
}

.countdown-item span {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

.video.medecin{
  position: relative;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
}
.video-container{
  max-height: 100%;
  max-width: 100%;
  background-color: #000;
}
#peerVid{
  position: absolute;
  height: calc(20vh - 2px);
  width: auto;
  bottom: 5%;
  right: 5%;
}
#myVid{
  height: calc(70vh - 2px);
  /* height: 70vh; */
  max-width: 100%;
  width: auto;
}

.responding{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

}

.responding.process .layer{
  position: absolute;
  /* margin-top: -4rem; */
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #000;
  opacity: .6;
}

.responding .caller{
    text-align: center;
    color: #fff;
    font-size: 18px;
    z-index: 9999;
    font-family: "Poppins", sans-serif; 
}
.responding .caller .name-caller{
  font-size: 20px;
  color: #038DFE;

}

.end-call{
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  opacity: 1;
}
.btn.disabled, .btn:disabled{
    cursor: not-allowed;
}
/* Small screens */

@media(max-width:991px) {
  /* .repondre #myVid {
    width: auto;
    height: 100px;
    top: 50px;
    background-color: #000;
  } */
    .responding .caller{
      font-size: 15px;
  }
  .link-container{
      width: 100%;
  }
  .button-toggle{
      margin-bottom: 15px;
      border: 0;
  }
  .button-toggle span{
      background : #fff;
  }
  .header-call .navbar-nav{
      justify-content: center;
      align-items: center;
  }

  .responding .banner-call div img{
    width: 35%;
  }
}

