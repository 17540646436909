main.medecin-site{
    background: #f5f8fa;
    position: relative;
    z-index: 1;
}

.sidebar .profil {
    margin-top: 25px;  
    padding: 20px;
    display: flex;
    position: relative;
   
  }

  .sidebar .profil .circle_online {
    left: 8%
  }
  .sidebar .profil .profile-img {
    max-width: 100%;
   width: 80%;
   border-radius: 50%;
   
  }
  
  
  .sidebar .nav .nav-item {
   width: 100%;
   margin-top: .8rem;
  
  }

  .circle_online{
    color: rgb(92, 184, 92);
    width: 1em;
    height: 1em;
    position: absolute;
    top: 12px;
    left: 4%;
  }
 
  .icon {
    color: #fff !important
  }
  .selecting-img-container, .rejected-img-container{
    /* width: auto; */
    /* height: 2rem; */
    display: flex;
    /* height: auto; */
    justify-content: center;
    position: relative;
  }
  .selecting-img-container img{
    border-radius: 15px;
  }
  .rejected-img-container img{
    /* width: 80px; */
    width: 40%;
    margin: 0 auto;
    border-radius: 15px;
  }
  .dropzone:focus {
    border-color: #2196f3 !important
  }
  .images-saved {
    padding: 12px;
    border : 1px solid #eee
  }
  .image-saved {
   position :relative;
   
  }
  .image-saved  .delete-button, .selecting-img-container .delete-button{
   position :absolute;
   right: -25px;
   top: -25px;
   color: #d92550;
   font-size: 24px
  }
  .image-saved  .delete-button:hover{
    color: #000
 
   }
  .image-saved img{
    margin: 0 auto;
    border-radius: 15px;
  }


  .discussion-container{
    border-radius: 0 0 15px 15px;
    box-shadow: 0 2px 8px rgba(54, 149, 235, 0.3);
  }
  .chat {
      border-radius: 8px;
      padding-bottom: 3rem;
  }
    .discussion{
      height: 70vh;
      border: 1.2px solid #008ea2;
      border-radius:  0 0 15px 15px;
      z-index: 9999;
    }
    .discussion .chat-messages {
      /* position: absolute; */
      height: 100%;
      width: 100%;
      /* top: 78px; */
      z-index: 9;
      /* border: 1.2px solid #008ea2; */
      border-radius: 0 0 15px 15px;
    }
    
    
  .discussion .chat-messages .content {
    height: calc(100% - 60px);
    overflow: hidden;
    position: relative;
    padding-bottom: 15px;
  }
  
  .messages {
    height: auto;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
  .messages::-webkit-scrollbar {
    width: 3px;
    background: rgba(0, 0, 0, 0);
  }
  .messages::-webkit-scrollbar-thumb {
    background-color: #008ea2;
  }
  .messages .messageContainer {
    display: inline-block;
    margin: 15px 15px 5px 15px;
    width: calc(100% - 25px);
    font-size: 0.9em;
  }
  .messages .messageContainer .message{
    display:flex;
    flex-direction: column;
  }
  .messages .messageContainer.sent .message{
    align-items: flex-end;
  }
  .messages .messageContainer.replies .message{
    align-items: flex-start;
  }
  .messages .messageContainer.replies .message .message-container{
    flex-direction: row-reverse;
  }
  .document-form .messageText p{
    padding: 10px;
  }
  .document-form .messageText p{
    padding: 10px;
  }

  .MuiPopover-paper {
    padding: 12px;
    border-radius: 12px !important;
    margin-top: 8px;
  }

  .messages .messageContainer:nth-last-child(1) {
    margin-bottom: 20px;
  }
  .messages .messageContainer.sent img {
  }
  
  .messages .messageContainer.sent .messageText, .document-form .messageText {
    background: #008ea2;
    color: #fff;
    overflow-wrap: break-word;
    border-radius: 10px;
  }
  .messages .messageContainer.sent .messageText p{
    color: #fff;

  }
  .messages .messageContainer .document-icon-container, .document-form .document-icon-container {
    padding: 15px;  
  }
  .messages .messageContainer .document-icon-container .document-icon, .document-form .document-icon-container .document-icon {
    padding: 15px;  
    border-radius: 50%;
    background-color: #002b4e;
  }
  .messages .messageContainer .document-icon svg{
    fill: #fff
    
  }
  .messages .messageContainer.replies .messageText {
    background: #f5f5f5;
    text-align:left;
    overflow-wrap: break-word;
    border-radius: 10px;
  }
  .messages .messageContainer p {
    display: inline-block;
    padding: 10px 15px;
    font-weight: 400;
    border-radius: 20px;
    max-width: 205px;
    line-height: 130%;
  }
  @media screen and (min-width: 735px) {
     .messages .messageContainer p {
        max-width: 300px;
    }
  
  }
  .discussion .messageForm{
    background-color:#f5f5f5;
    padding: 12px 0px;
    position: absolute;
    width: calc(100% - 1px);
    bottom: 1.8px;
    right: 0.8px;
    border-radius: 0 0 15px 14px;
  }

  
  .discussion .chatContainer .inputChat {
    font-family: "Poppins";
    border: 0.5px solid rgba(0,0,0,0.11);
    width: 100%;
    padding: 11px 32px 10px 20px;
    font-size: 1em;
    color: #32465a;
    background-color: rgba(0,0,0,0.1);
    border-radius: 30px;
  }
  
  .discussion .chatContainer .inputChat:focus {
    outline: none;
  }
  
  .discussion .chatContainer .SendMsgBtn, .document-form .SendMsgBtn{
    border: none;
    padding: 10px 12px;
    cursor: pointer;
    color: #f5f5f5;
    background: #008ea2;
    border-radius: 100%;
    transition: all 0.3s;
  }
  .document-form .SendMsgBtn{
    padding: 10px 20px;
  }
  
  .discussion .chatContainer .SendMsgBtn:hover, .document-form .SendMsgBtn:hover {
    background: #435f7a;
  }
  
  .discussion .chatContainer .SendMsgBtn:focus, .document-form .SendMsgBtn:focus {
    outline: none;
  }
  .discussion .chatContainer .SendMsgBtn svg, .document-form .SendMsgBtn svg {
    margin-right: 2px;
  }
  
  @media screen and (max-width: 735px) {
    .discussion .chatContainer .inputChat {
      padding: 15px 32px 16px 8px;
  }
 
  }

  .infoBar {
    height: 78px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #008ea2;
    border-radius: 4px 4px 0 0;
    width: 100%;
    padding-right: 15px;

  }
  .action{
    border-radius: 50%;
    /* margin-left: .4rem;
    margin-right: .5rem; */
    padding: 18px;
    opacity: 1;
}
.video-call .action{
  background-color: transparent;

}
.phone-call .action{
  background-color: transparent;
}
.leftInnerContainer {
  display: flex;
  align-items: center;
  margin-left: 5%;
  color: white;
}

.rightInnerContainer {
  display: flex;
  flex: 0.5;
  justify-content: flex-end;
  margin-right: 5%;
}

.centerInnerContainer {
  display: flex;
  flex: 0.5;
  justify-content: flex-end;
}

.onlineIcon {
  margin-right: 5%;
}
.profile {
  width: 100%;
  padding: 4px;
  background: #008ea2;
  color: #fff;
  display: flex;
  align-items: center;
}
.profile img {
  width: 70px;
  border-radius: 50%;
  padding: 3px;
  background-color: #fff;
  border: 2px solid #008ea2;
  height: auto;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
  margin-right: 1rem;
}

.profile img.online {
  border: 2px solid #2ecc71;
}

.profile img.away {
  border: 2px solid #f1c40f;
}

.profile img.busy {
  border: 2px solid #e74c3c;
}

.profile img.offline {
  border: 2px solid #95a5a6;
}
.profile p {
color: #f5f5f5;
font-size: 18px;
}


.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: #111;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
}

.countdown-item span {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

.video.medecin{
  position: relative;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
}
.video-container{
  max-height: 100%;
  max-width: 100%;
  background-color: #000;
}
#peerVid{
  position: absolute;
  height: calc(20vh - 2px);
  width: auto;
  bottom: 5%;
  right: 5%;
}
#myVid{
  height: calc(70vh - 2px);
  /* height: 70vh; */
  max-width: 100%;
  width: auto;
}

.responding{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

}

.responding.process .layer{
  position: absolute;
  /* margin-top: -4rem; */
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #000;
  opacity: .6;
}

.responding .caller{
    text-align: center;
    color: #fff;
    font-size: 18px;
    z-index: 9999;
    font-family: "Poppins", sans-serif; 
}
.responding .caller .name-caller{
  font-size: 20px;
  color: #038DFE;

}

.end-call{
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  opacity: 1;
}
.btn.disabled, .btn:disabled{
    cursor: not-allowed;
}
/* Small screens */

@media(max-width:991px) {
  /* .repondre #myVid {
    width: auto;
    height: 100px;
    top: 50px;
    background-color: #000;
  } */
    .responding .caller{
      font-size: 15px;
  }
  .link-container{
      width: 100%;
  }
  .button-toggle{
      margin-bottom: 15px;
      border: 0;
  }
  .button-toggle span{
      background : #fff;
  }
  .header-call .navbar-nav{
      justify-content: center;
      align-items: center;
  }

  .responding .banner-call div img{
    width: 35%;
  }
}
